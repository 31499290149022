<template>
  <div class="cross-detect">
    <div class="top">
      <div class="container width-percent80 line-center">
        <h2 class="title">
          <img src="../../assets/cross_detect.png" />
          <span>ISLI资源关联库</span>
        </h2>
        <ul class="industry width-percent80 line-center">
          <li
            class="lists"
            @click="industrySearch(item)"
            v-for="(item, index) in industryData"
            :key="index"
          >
            <span>{{ item }}</span>
          </li>
        </ul>
        <div class="search-area center">
          <el-input
            v-model="valueData"
            placeholder="请输入关键词"
            @keyup.enter.native="handleIconClick(1)"
          >
            <i
              class="el-input__icon el-icon-search"
              slot="suffix"
              style="cursor:pointer;"
              @click="handleIconClick(1)"
            ></i>
          </el-input>
          <el-radio-group class="radio" v-model="radio" @change="clickRadioSearch()">
            <el-radio
              v-for="(item, index) in radioData"
              :key="index"
              :label="index"
            >{{ item.title }}</el-radio>
          </el-radio-group>

          <div v-show="showOfDisk" class="margin-t-30">
            <span class="text-white">
              <!-- 已收录<font color>{{ totalNum }} 条数据</font> -->
              已收录
              <font color>{{ radioData[selectNum].num }} 条数据</font>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="search-result2" v-show="searchResult2 && radio == radioFlag">
      <img :src="response_img" style="width:100%;" />
    </div>
    <div class="search-result line-center">
      <div class="body listshow1" ref="bodyData" v-show="searchShows">
        <!-- 文章显示区域 -->
        <div ref="from" v-loading="loading" class="show-area line-center width-percen">
          <!-- 文章显示区域 -->

          <!-- 期刊文献 -->
          <div v-if="radio == 0 && radioFlag == 0 ? true : false">
            <!-- <dl class="show-list" v-for="(item, index) in respData0" :key="index">
              <dt v-html="item.title"></dt>
              <dd class="show-lists">
                <img :src="item.img" />
                <p v-html="item.abstract"></p>
              </dd>
              <a
                @click="downPdf(item.downloadUrl) && item.flag"
                :class="{
                  noDtl: item.articleright == '暂无详情' ? true : false,
                  noDtl2: item.articleright == '下载详情' ? true : false,
                }"
              >{{ item.articleright }}</a>
            </dl>-->
            <el-table :data="respData0" style="width: 100%">
              <el-table-column show-overflow-tooltip header-align="left" label="题名">
                <template slot-scope="scope">
                  <div class="text_left" v-html="scope.row.title"></div>
                </template>
              </el-table-column>
              <el-table-column label="作者">
                <template slot-scope="scope">
                  <div class="text_left">{{scope.row.writer}}</div>
                </template>
              </el-table-column>
              <el-table-column show-overflow-tooltip label="来源">
                <template slot-scope="scope">
                  <div class="text_left">{{scope.row.organization}}</div>
                </template>
              </el-table-column>
              <el-table-column label="发表年份" width="150">
                <template slot-scope="scope">
                  <div class="text_left">
                    <span>{{scope.row.year}}</span>
                    <span class="guanlian" @click="guanlian(scope.row,0)">关联</span>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <!-- 专业词条 -->
          <div v-show="radio == 1 && radioFlag == 1 ? true : false">
            <!-- <dl class="show-list" v-for="(item, index) in respData1" :key="index">
              <dt v-html="item.Title"></dt>
              <dd class="show-lists">
                <img :src="item.DescribeImage" />
                <p v-html="item.DescribeText"></p>
              </dd>
              <a class="noDtl" :href="item.DetailUrl" target="_blank">{{ item.DetailUrl }}</a>
            </dl>-->
            <el-table :data="respData1" style="width: 100%">
              <el-table-column show-overflow-tooltip header-align="left" label="题名">
                <template slot-scope="scope">
                  <div class="text_left" v-html="scope.row.title"></div>
                </template>
              </el-table-column>
              <el-table-column label="作者">
                <template slot-scope="scope">
                  <div class="text_left">{{scope.row.writer}}</div>
                </template>
              </el-table-column>
              <el-table-column show-overflow-tooltip label="来源">
                <template slot-scope="scope">
                  <div class="text_left">{{scope.row.organization}}</div>
                </template>
              </el-table-column>
              <el-table-column label="发表年份" width="150">
                <template slot-scope="scope">
                  <div class="text_left">
                    <span>{{scope.row.year}}</span>
                    <span class="guanlian" @click="guanlian(scope.row,1)">关联</span>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>

          <!-- 专利库 -->
          <div v-if="radio == 2 && radioFlag == 2 ? true : false">
            <dl class="show-list" v-for="(item, index) in respData2" :key="index">
              <dt>
                <span v-html="item.title"></span>
                <span class="guanlian" @click="guanlian(item,2)">关联</span>
              </dt>
              <dd class="show-lists">
                <img :src="item.describeImage" />
                <p v-html="item.describeText">}</p>
              </dd>
              <a :href="item.detailUrl" target="_blank">详情:{{ item.detailUrl }}</a>
            </dl>
          </div>

          <!-- 知识头条 -->
          <div v-if="radio == 3 && radioFlag == 3 ? true : false">
            <!-- <dl
              @click="goToArticleDetail(item.id)"
              class="show-list"
              v-for="(item, index) in respData3"
              :key="index"
            >
              <dt v-html="item.title" class="pointer"></dt>
              <dd class="show-lists">
                <img class="pointer" :src="item.coverImg" />
                <p class="pointer" v-html="item.content"></p>
              </dd>
            </dl>-->
            <el-table :data="respData3" style="width: 100%">
              <el-table-column show-overflow-tooltip header-align="left" label="题名">
                <template slot-scope="scope">
                  <div class="text_left" v-html="scope.row.title"></div>
                </template>
              </el-table-column>
              <el-table-column label="作者">
                <template slot-scope="scope">
                  <div class="text_left">{{scope.row.writer}}</div>
                </template>
              </el-table-column>
              <el-table-column show-overflow-tooltip label="简介">
                <template slot-scope="scope">
                  <div class="text_left">{{scope.row.organization}}</div>
                </template>
              </el-table-column>
              <el-table-column label="发表年份" width="150">
                <template slot-scope="scope">
                  <div class="text_left">
                    <span>{{scope.row.year}}</span>
                    <span class="guanlian" @click="guanlian(scope.row,3)">关联</span>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <!-- 智能问答 -->
          <div v-if="radio == 4 && radioFlag == 4 ? true : false">
            <!-- <dl
              @click="goTowriteanswerDetail(item.id)"
              class="show-list"
              v-for="(item, index) in respData4"
              :key="index"
            >
              <dt v-html="item.questionTitle" class="pointer"></dt>
              <dd class="show-lists">
                <img :src="item.img" class="pointer" />
                <p class="pointer" v-html="item.questionContent"></p>
              </dd>
            </dl>-->
            <el-table :data="respData4" style="width: 100%">
              <el-table-column show-overflow-tooltip header-align="left" label="题名">
                <template slot-scope="scope">
                  <div class="text_left" v-html="scope.row.title"></div>
                </template>
              </el-table-column>
              <el-table-column label="作者">
                <template slot-scope="scope">
                  <div class="text_left">{{scope.row.writer}}</div>
                </template>
              </el-table-column>
              <el-table-column show-overflow-tooltip label="简介">
                <template slot-scope="scope">
                  <div class="text_left">{{scope.row.organization}}</div>
                </template>
              </el-table-column>
              <el-table-column label="操作" width="100">
                <template slot-scope="scope">
                  <!-- <div class="text_left"> -->
                  <!-- <span>{{scope.row.year}}</span> -->
                  <span class="guanlian" @click="guanlian(scope.row,4)">关联</span>
                  <!-- </div> -->
                </template>
              </el-table-column>
            </el-table>
          </div>
          <!-- 全球产品 -->
          <div v-if="radio == 5 && radioFlag == 5 ? true : false">
            <!-- <dl class="show-list" v-for="(item, index) in respData5" :key="index">
              <dt v-html="item.Title"></dt>
              <dd class="show-lists">
                <img :src="item.DescribeImage" />
                <p v-html="item.DescribeText"></p>
              </dd>
              <a :href="item.DetailUrl" target="_blank">详情:{{ item.DetailUrl }}</a>
            </dl>-->
            <el-table :data="respData5" style="width: 100%">
              <el-table-column show-overflow-tooltip header-align="left" label="题名">
                <template slot-scope="scope">
                  <div class="text_left" v-html="scope.row.title"></div>
                </template>
              </el-table-column>
              <el-table-column label="作者">
                <template slot-scope="scope">
                  <div class="text_left">{{scope.row.writer}}</div>
                </template>
              </el-table-column>
              <el-table-column show-overflow-tooltip label="内容">
                <template slot-scope="scope">
                  <div class="text_left">{{scope.row.content}}</div>
                </template>
              </el-table-column>
              <el-table-column label="发表年份" width="150">
                <template slot-scope="scope">
                  <div class="text_left">
                    <span>{{scope.row.year}}</span>
                    <span class="guanlian" @click="guanlian(scope.row,5)">关联</span>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <!-- 分页 -->
        <div v-show="respTotal > 0 && respTotal2 > 0 ? true : false">
          <div
            class="foot width-percent100 line-center"
            v-show="radioBrother == radio ? true : false"
          >
            <el-pagination
              background
              @current-change="handleCurrentPageChange"
              layout="prev, pager, next"
              :page-size="pageSize"
              :page-count="page_count"
            ></el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { DELETE, QUERYED, INSERT, UPDATE, QUERY } from "@/services/dao.js";
import { eventBus } from "@/js/common/eventBus";
import axios from "axios";
import { post, get } from "@/services/http.js";
import { from } from "apollo-link";
import { Form } from "element-ui";
import {
  dataFormat,
  formatDate,
  integralAddTwo,
  showTimeYear,
  AcceptNum,
  AcceptNumTeam
} from "@/js/common/index.js";
export default {
  data() {
    return {
      valueData: "", // 输入的搜索信息
      radio: 0, // 单选框checked
      radioFlag: 0, // 控制没有发送请求的时候的内容展示框显隐
      totalNum: 0,
      showOfDisk: false,
      industryData: [
        "计算机科学",
        "材料科学",
        "电工电子",
        "教育学",
        "图书情报",
        "合成生物学",
        "人工智能",
        "机械工程",
        "物理学",
        "海洋科学",
        "大气科学",
        "地理科学",
        "网络经济",
        "生物经济",
        "绿色低碳",
        "数字创意",
        "高端制造",
        "新材料"
      ], // 领域
      radioData: [
        { title: "期刊文献库", num: 71101407 },
        { title: "专业词条库", num: 6438941 },
        { title: "专利库", num: 110862336 },
        { title: "知识头条", num: 11650 },
        { title: "智能问答", num: 3769 },
        { title: "全球产品库", num: 5038617 }
      ],
      selectNum: 0,
      // 搜索结果数据
      // 期刊文献
      respData0: [{ title: "", abstract: "" }],
      // 专业词条
      respData1: [
        { Title: "", DescribeText: "", DescribeImage: "", DetailUrl: "" }
      ],
      // 专利库
      respData2: [{ title: "", describeText: "", describeImage: "" }],
      // 知识头条
      respData3: [{ title: "", coverImg: "", content: "" }],
      // 智能问答
      respData4: [{ questionTitle: "", questionContent: "" }],
      // 全球产品库
      respData5: [],
      pageSize: 10, // 每页默认显示10条数据
      currentPage: 1, // 当前页
      page_count: 0, // 总页数默认为0
      loading: false,
      searchShows: false, // 搜索后显示结果，默认关闭
      initDiv: true,
      radioBrother: 0, //按钮显示框 与radio默认值同步
      respTotal: 0, // 返回的搜索数据总条数，默认值 0
      respTotal2: 0, // 返回的数据 rows 中的 length
      searchResult2: false, // 搜索无结果提示框
      response_img: "", // 超时或无结果是提示图片
      tableData: []
    };
  },
  created() {
    // 如果searchK有值，默认执行查询  期刊文献库
    let searchK = this.$route.query.searchK;
    if (searchK) {
      this.valueData = searchK;
      this.radio = 0;
      this.handleIconClick();
    }
  },
  mounted() {},
  watch: {
    valueData: function(newVal, oldVal) {
      if (newVal != oldVal) this.showOfDisk = false;
    },
    radio: function(newVal, oldVal) {
      if (newVal != oldVal) this.showOfDisk = false;
    }
  },
  ///退出页面

  beforeDestroy() {
    if (this.$route.query.type == 0) {
      eventBus.$emit("updateLsliInfo");
    }
  },
  methods: {
    //关联
    async guanlian(row, num) {
      let isli_url = null;
      //路径
      if (num == 3) {
        isli_url = window.location.host + "/articleDetail?id=" + row.id;
      } else if (num == 4) {
        isli_url = window.location.host + "/writeanswer?id=" + row.id;
        if (row.content == "" || row.content == null) {
          row.content = row.title; //内容为空赋值标题
        }
      } else if (num == 2) {
        isli_url = "";
        row.content = row.describeText;
      } else {
        isli_url = row.downloadUrl;
      }
      const recorderset = [{}];
      recorderset[0].isli_url = isli_url;
      recorderset[0].isli_title = row.title;
      recorderset[0].isli_author = row.writer;
      recorderset[0].isli_content = row.content;
      recorderset[0].taskId = this.$route.query.taskId; //this.$route.query.taskId
      recorderset[0].type = this.$route.query.type; //this.$route.query.type/0:课题五，1：课题二，2：课题三
      await post("/common/creatISLIAndSend", {
        resourceType: 10,
        token: localStorage.getItem("Authorization"),
        recorderset: recorderset
      })
        .then(res => {
          if (res.success == true) {
            this.cheng();
            this.$router.go(-1);
          } else {
            this.shibai();
          }
        })
        .catch(function(error) {
          this.shibai();
        });
    },
    cheng() {
      this.$message({
        message: "关联成功！",
        type: "success"
      });
    },
    shibai() {
      this.$message.error("关联失败！");
    },
    // 点击领域搜索
    industrySearch(value) {
      this.valueData = value;
      this.searchShows = true;
      this.loading = true;

      this.requireApi(value);
    },
    // 点击radio搜索
    clickRadioSearch() {
      this.handleIconClick();
    },
    async handleIconClick(num) {
      if (!this.valueData != "") {
        this.$message.warning("请输入搜索内容!");
        return;
      }

      this.searchShows = true;
      this.loading = true;
      setTimeout(this.requireApi(), 0);
    },
    // 当前页发生变化
    handleCurrentPageChange(val) {
      this.currentPage = val;
      if (!this.valueData != "") {
        this.$message.warning({ message: "请输入搜索内容!" });
        return;
      }
      this.requireApi();
    },
    ///分类查询
    requireApi(value) {
      this.searchResult2 = false;

      const formData = new FormData(); //为XHR传参提供便利
      formData.append("keywords", this.valueData || value);
      formData.append("page", this.currentPage);
      formData.append("count", this.pageSize);
      formData.append("type", this.radio);
      axios
        .post(this.api.LoginURL.concat("/common/unifiedSearch"), formData)
        .then(res => {
          this.radioFlag = this.radio; // 赋值给未发送请求的内容展示框类型值
          let temp_tip = document.getElementsByClassName("search-result2");
          if (res.data.success) {
            if (res.data.data == null || res.data.data.total == 0) {
              this.searchResult2 = true;
              this.searchShows = false;
              this.showResultNoneImgsrc();
              this.showOfDisk = false;
              return;
            }

            this.page_count = Math.ceil(res.data.data.total / 10.0);
            const data = res.data.data.rows;
            this.respTotal = res.data.data.total;
            this.respTotal2 = res.data.data.rows.length;
            this.totalNum = res.data.data.totalNum;
            this.saveRespResult(this.radio, data);
          } else {
            // 连接超时
            this.searchResult2 = true;
            this.searchShows = false;
            this.showTimeoutImgsrc();
            this.respTotal = 0; // 没返回搜索结果条数 赋0
            this.searchShows = false;
            this.loading = false;
            this.initDiv = false;
            this.radioBrother = this.radio; // this.radioBrother =  type?
            this.showOfDisk = false;
            return;
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    // 处理返回数据
    async saveRespResult(type, data) {
      let reger1 = new RegExp(this.valueData, "gm");
      if (type == 0) {
        this.respData0 = [];
        for (let i = 0; i < data.length; i++) {
          this.respData0.push({
            title:
              data[i].title &&
              data[i].title.replace(
                reger1,
                '<font color="red">' + this.valueData + "</font>"
              ),
            content:
              data[i].abstract &&
              data[i].abstract.replace(
                reger1,
                '<font color="red">' + this.valueData + "</font>"
              ),
            writer: data[i].writer == "" ? "期刊文献库" : data[i].writer, //作者
            organization: data[i].organization, //来源
            year: data[i].year, //年份
            articleright:
              data[i].downloadUrl == undefined ? "暂无详情" : "下载详情",
            downloadUrl:
              data[i].downloadUrl == undefined ? "" : data[i].downloadUrl,
            flag: data[i].downloadUrl == undefined ? false : true,
            id: data[i].id
          });
        }
      } else if (type == 1) {
        this.respData1 = [];
        for (let i = 0; i < data.length; i++) {
          this.respData1.push({
            title:
              data[i].Title &&
              data[i].Title.replace(
                reger1,
                '<font color="red">' + this.valueData + "</font>"
              ),
            DescribeText:
              data[i].DescribeText &&
              data[i].DescribeText.replace(
                reger1,
                '<font color="red">' + this.valueData + "</font>"
              ), //详情
            writer: "词条库", //作者
            organization: data[i].organization, //来源
            DetailUrl: data[i].DetailUrl, //地址
            year: data[i].year, //年份
            DescribeImage: data[i].DescribeImage
            // DetailUrl: data[i].DetailUrl
          });
        }
      } else if (type == 2) {
        this.respData2 = [];
        for (let i = 0; i < data.length; i++) {
          this.respData2.push({
            title:
              data[i].title &&
              data[i].title.replace(
                reger1,
                '<font color="red">' + this.valueData + "</font>"
              ),
            detailUrl: data[i].detailUrl, //路径
            writer: data[i].inventor, //作者
            describeText: data[i].describeText, //描述
            describeImage: data[i].describeImage
          });
        }
      } else if (type == 3) {
        this.respData3 = [];
        for (let i = 0; i < data.length; i++) {
          this.respData3.push({
            // {Title: "",DescribeText: "",DescribeImage: "",DetailUrl: ""}
            title:
              data[i].title &&
              data[i].title.replace(
                reger1,
                '<font color="red">' + this.valueData + "</font>"
              ),
            content:
              data[i].content &&
              data[i].content
                .replace(/<\/?.+?>/g, "")
                .replace(/&nbsp;/g, " ")
                .replace(
                  reger1,
                  '<font color="red">' + this.valueData + "</font>"
                ),
            writer: data[i].authorName, //作者
            organization: data[i].intro, //简介
            year: showTimeYear(data[i].createTime), //年份
            downloadUrl: data[i].reference, //路径url
            coverImg: this.api.LoginURL.concat(data[i].coverImg),
            id: data[i].id
          });
        }
      } else if (type == 4) {
        this.respData4 = [];
        for (let i = 0; i < data.length; i++) {
          //questioner:通过userID查找用户名
          let User = await QUERYED(
            "POST",
            "",
            "  User(where: {id : {_eq: " +
              data[i].questioner +
              "}}) {  loginName   }"
          );
          this.respData4.push({
            // {Title: "",DescribeText: "",DescribeImage: "",DetailUrl: ""}
            title:
              data[i].questionTitle &&
              data[i].questionTitle.replace(
                reger1,
                '<font color="red">' + this.valueData + "</font>"
              ),
            content:
              data[i].questionContent &&
              data[i].questionContent.replace(
                reger1,
                '<font color="red">' + this.valueData + "</font>"
              ),
            // coverImg: this.api.LoginURL.concat(data[i].coverImg),
            id: data[i].id,
            writer: User.data.User[0].loginName //作者
          });
        }
      } else {
        this.respData5 = [];
        for (let i = 0; i < data.length; i++) {
          this.respData5.push({
            // {Title: "",DescribeText: "",DescribeImage: "",DetailUrl: ""}
            DescribeImage: data[i].DescribeImage,
            content:
              data[i].DescribeText &&
              data[i].DescribeText.replace(
                reger1,
                '<font color="red">' + this.valueData + "</font>"
              ),
            title:
              data[i].Title &&
              data[i].Title.replace(
                reger1,
                '<font color="red">' + this.valueData + "</font>"
              ),
            writer: "全球产品库",
            downloadUrl: data[i].DetailUrl
            // coverImg: this.api.LoginURL.concat(data[i].coverImg),
          });
        }
      }
      this.loading = false;
      this.initDiv = false;
      this.radioBrother = type;
      this.showOfDisk = true;
    },
    downPdf(url) {
      if (url != "") {
        let realUrl = url.split('["')[1].split('"]')[0];
        window.open(realUrl, "_self");
      }
    },
    showTimeoutImgsrc() {
      this.response_img = require("../../assets/connection_out_time.png");
    },
    showResultNoneImgsrc() {
      this.response_img = require("../../assets/result_none.png");
    },
    goToArticleDetail(id) {
      this.$router.push({
        path: "/articleDetail",
        query: {
          id: id
        }
      });
    },
    // 智能问答详情
    goTowriteanswerDetail(id) {
      this.$router.push({
        path: "/writeanswer",
        query: {
          id: id
        }
      });
    }
    // 点击跳转到具体位置
    // showDetails(num){
    //     var el=document.getElementsByClassName(`listshow${num}`)[0];
    //     console.log(el.offsetTop);
    //     if(this.showIndex==num){
    //         this.showIndex=null;
    //     }else{
    //         this.showIndex=num;
    //     }
    //     this.$nextTick(function () {
    //         window.scrollTo({"behavior":"smooth","top":el.offsetTop});
    //     })
    // }
  }
};
</script>
<style scoped>
/* public style */
body {
  margin: 0;
  padding: 0;
}
body div {
  box-sizing: border-box;
}
h2 {
  margin-block-start: 0;
  margin-block-end: 0;
  padding-top: 20px;
  padding-bottom: 20px;
}
.width-percent80 {
  width: 80%;
}
.width-percent90 {
  width: 90%;
}
.line-center {
  margin: 0 auto;
}
.center {
  text-align: center;
}
ul {
  padding-inline-start: 0;
}
.text-white {
  color: #fff;
  font-size: 14px;
}
/* private style */
.title {
  color: #f9fafb;
  letter-spacing: 10px;
}
.title span {
  vertical-align: 10px;
  margin-left: 11px;
  font-size: 0.169118rem;
}
.title:hover {
  color: #f9fafb;
}
.cross-detect {
  box-sizing: border-box;
}
.top {
  /* background: #f7f7f7; */
  background-image: url("~@/assets/cross_detect_bkgd.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  /* font-size: 0; */
}
.industry {
  padding-top: 50px;
  padding-bottom: 50px;
}
.container {
}

.show-area {
  background: white;
  min-height: 50px;
}
.bounder {
  height: 20px;
  background-color: #6b8dcd;
}
.lists {
  float: left;
  width: 15%;
  border: 1px solid #76bdff;
  text-align: center;
  color: #0463bd;
  height: 24px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 15px;
  background-color: white;
  cursor: pointer;
}
.lists:not(:nth-child(6n + 1)) {
  margin-left: 14px;
}
.industry {
  margin-bottom: 30px;
  margin-top: 30px;
}
.industry::after {
  display: block;
  content: "";
  clear: both;
}
.search-area {
  padding-bottom: 50px;
}
.show-area {
  padding-left: 20px;
  padding-right: 20px;
}
.radio {
  margin-top: 50px;
  margin-right: -100px;
}
.show-list {
  border-bottom: 1px dashed #949494;
  padding-bottom: 15px;
}
.show-list .show-lists {
  margin-inline-start: 0px;
}
.show-list img {
  /* float: left; */
  vertical-align: top;
  max-width: 150px;
  height: auto;
  margin-right: 10px;
}
.show-list dt {
  font-size: 16px;
  line-height: 49px;
  letter-spacing: 2px;
  /* word-spacing: normal; */
  overflow: hidden;
  /* border: 1px solid red; */
  text-overflow: ellipsis;
  width: 98%;
  height: 49px;
  white-space: nowrap;
  font-family: "Arial", "Microsoft YaHei", "黑体", "宋体", sans-serif;
}
.show-lists p {
  display: inline-block;
  font-size: 13px;
  word-break: break-all;
  width: 96%;
  /* border: 1px solid red; */
  height: 95px;
  /* background: #79797f; */
  line-height: 24px;
  font-family: Helvetica, "Hiragino Sans GB", "Microsoft Yahei", "微软雅黑",
    Arial, sans-serif;
  letter-spacing: 1px;
  margin-block-start: 0;
  margin-block-end: 0;
  overflow: hidden;
}
.foot {
  background-color: #ffffff;
  /* padding-top: 50px;
  padding-bottom: 50px; */
  text-align: center;
  /* width: 100%; */
  /* display: inline-block; */
  width: 80%;
  margin: auto;
  position: relative;
  top: -10px;
  padding: 10px 0px 10px 0px;
}
.search-result,
.foot {
  /* margin-top: 50px; */
}
.initDiv {
  height: 300px;
}
.noDtl {
  color: #cccccc;
}
.noDtl2 {
  color: #cccccc;
  cursor: pointer;
}
.pointer {
  cursor: pointer;
}
.margin-t-30 {
  margin-top: 30px;
}
/* elementUI reset style*/
.search-area >>> .el-input__inner {
  width: 80%;
}
.el-icon-search {
  color: #2283f9;
  font-size: 28px;
}
.search-area >>> .el-input__suffix {
  width: 25%;
}
.radio >>> .el-radio {
  color: white;
  cursor: pointer;
  margin-right: 103px;
}

.radio >>> .el-radio__input.is-checked + .el-radio__label {
  color: white;
}
.foot >>> .el-pagination.is-background .el-pager li {
  margin: 0 10px;

  color: #606266;
  min-width: 35px;
  border-radius: 2px;
  height: 35px;
  line-height: 35px;
  box-sizing: border-box;
  text-align: center;
}
.foot >>> .btn-prev,
.foot >>> .btn-next {
  min-width: 35px;
  border-radius: 2px;
  height: 35px;
  line-height: 35px;
  box-sizing: border-box;
  text-align: center;
}
.foot >>> .el-pagination.is-background .el-pager li:not(.disabled):hover {
  color: #409eff;
}
.foot >>> .el-pagination.is-background .el-pager li:not(.disabled).active {
  color: #fff;
}
.text_left {
  text-align: left;
}
::v-deep tr > th .cell {
  text-align: left;
}
.guanlian {
  font-size: 13px;
  width: 45px;
  height: 22px;
  line-height: 22px;
  /* display: inline-block; */
  border-radius: 5px;
  text-align: center;
  background-color: #3e94f7;
  color: #ffffff;
  border: 1px solid #3e94f7;
  float: right;
}
.width-percen {
  width: 1150px;
}
.width-percent100 {
  width: 1150px;
  padding-top: 20px;
}
</style>
